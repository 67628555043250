<template>
  <div>
    <!-- MODAL FILTROS -->
    <modal
      :show-modal="showModal"
      class="modal-parametros-compra"
      titulo="Alterar parâmetros"
      @close="close()"
    >
      <!-- MODAL FILTRO CONTENT -->
      <div slot="body">
        <div class="modal-container">
          <label v-show="dadosPedido.razao.length > 1">Razão social</label>

          <cool-select
            v-show="dadosPedido.razao.length > 1"
            id="modal-container-select-razao"
            v-model="selectsPedido.razao"
            :items="dadosPedido.razao"
            item-value="id"
            item-text="fullName"
            placeholder="Selecione uma razão"
            menu-items-max-height="200px"
            @select="buscaTabelaVendas"
          >
          </cool-select>
          <!-- <select
            v-show="dadosPedido.razao.length > 1"
            v-model="selectsPedido.razao"
            class="form-control"
        
          >
            <option
              v-for="value in dadosPedido.razao"
              :key="value.id"
              :value="value.id"
            >
              {{ formataDocumento(value.cpfcnpj) }} - {{ value.nomerazao }} -
              {{ value.cidade }} - {{ value.uf }}
            </option>
          </select> -->
          <div></div>
          <label>Tabela de venda</label>
          <!-- <small
            v-if="!dadosPedido.tabvnd.length && !active_blackmodal"
            class="text-danger"
          >
            Nenhuma tabela disponível<template
              v-if="dadosPedido.razao.length > 1"
            >
              para a razão selecionada</template
            >. Em caso de dúvidas, contate o suporte.
          </small> -->

          <small
            v-if="
              selectsPedido.status &&
              !selectsPedido.status.tabvnd &&
              !tabvndIsValid
            "
            class="text-danger"
          >
            A tabela de venda
            {{ selectsPedido.informacoes.tabvnd }} não está mais disponível.
            Selecione uma nova tabela de venda.
          </small>

          <select
            v-model="selectsPedido.tabvnd"
            class="form-control"
            :disabled="
              (dadosPedido.tabvnd && dadosPedido.tabvnd.length < 0) ||
              !dadosPedido.tabvnd
            "
            @change="buscaFormaPagamento"
          >
            <option
              v-for="value in dadosPedido.tabvnd"
              :key="value.id"
              :value="value.id"
            >
              {{ value.descricao }}
            </option>
          </select>

          <label>Forma de pagamento</label>
          <small
            v-if="
              selectsPedido.status &&
              !selectsPedido.status.formaPagamento &&
              !formaPagamentoIsValid
            "
            class="text-danger"
          >
            A forma de pagamento
            {{ selectsPedido.informacoes.formaPagamento }} não está mais
            disponível. Selecione uma nova forma de pagamento.
          </small>
          <select
            v-model="selectsPedido.formaPagamento"
            class="form-control"
            :disabled="
              (dadosPedido.formaPagamento &&
                dadosPedido.formaPagamento.length < 0) ||
              !dadosPedido.formaPagamento
            "
            @change="buscaCondicaoPagamento"
          >
            <option
              v-for="value in dadosPedido.formaPagamento"
              :key="value.id"
              :value="value.id"
            >
              {{ value.descricao }}
            </option>
          </select>

          <label>Condição de pagamento</label>
          <small
            v-if="
              selectsPedido.status &&
              !selectsPedido.status.condicaoPagamento &&
              !condicaoPagamentoIsValid
            "
            class="text-danger"
          >
            A condição de pagamento
            {{ selectsPedido.informacoes.condicaoPagamento }} não está mais
            disponível. Selecione uma nova condição de pagamento.
          </small>
          <select
            v-model="selectsPedido.condicaoPagamento"
            class="form-control"
            :disabled="
              (dadosPedido.condicaoPagamento &&
                dadosPedido.condicaoPagamento.length < 0) ||
              !dadosPedido.condicaoPagamento
            "
          >
            <option
              v-for="value in dadosPedido.condicaoPagamento"
              :key="value.id"
              :value="value.id"
            >
              {{ value.descricao }}
            </option>
          </select>
        </div>

        <div v-if="error" class="error-message">
          <b class="text-danger">
            {{ mensagem }}
          </b>
        </div>
      </div>
      <div slot="footer">
        <button class="btn btn-primary" @click="saveDadosPedidos()">
          Salvar Parâmetros
        </button>
      </div>
    </modal>
    <!-- <blackmodal class="black-modal" :active="active_blackmodal">
      <img width="110" src="@/assets/img/loading/loading.gif" alt="" />
    </blackmodal> -->
  </div>
</template>

<script>
import { CoolSelect } from "vue-cool-select";
import modal from "@/components/root/modal/Modal";
import blackmodal from "@/components/root/black-modal/blackModal";
import * as global from "@/app/global";
import bus from "@/utils/events/bus";

export default {
  name: "ModalParametros",
  components: { modal, blackmodal, CoolSelect },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectsPedido: {
        razao: "",
        unidade: "",
        tabvnd: "",
        formaPagamento: "",
        condicaoPagamento: "",
        pedidoId: "",
        informacoes: {},
        oldClienteId: "",
      },
      dadosPedido: {
        razao: [],
        unidade: [],
        tabvnd: [],
        formaPagamento: [],
        condicaoPagamento: [],
      },
      active_blackmodal: false,
      error: 0,
      mensagem: "",
    };
  },
  computed: {
    condicaoPagamentoIsValid() {
      return (
        this.dadosPedido.condicaoPagamento &&
        this.dadosPedido.condicaoPagamento.findIndex(
          (x) => x.id == this.selectsPedido.condicaoPagamento
        ) >= 0
      );
    },
    tabvndIsValid() {
      return (
        this.dadosPedido.tabvnd &&
        this.dadosPedido.tabvnd.findIndex(
          (x) => x.id == this.selectsPedido.tabvnd
        ) >= 0
      );
    },
    formaPagamentoIsValid() {
      return (
        this.dadosPedido.formaPagamento &&
        this.dadosPedido.formaPagamento.findIndex(
          (x) => x.id == this.selectsPedido.formaPagamento
        ) >= 0
      );
    },
  },
  watch: {
    active_blackmodal(val) {
      this.$store.dispatch("setShowLoadingModal", [
        val,
        "modalParametrosCompra",
      ]);
    },
  },
  created() {
    const clienteAtual = localStorage.getItem("clienteAtual");

    if (Object.keys(this.$store.getters.dadosPedido).length !== 0) {
      const object = { ...this.$store.getters.dadosPedido };

      this.selectsPedido.pedidoId = object.pedidoId;
      this.selectsPedido.razao = object.razao;
      this.selectsPedido.unidade = object.unidade;
      this.selectsPedido.tabvnd = object.tabvnd;
      this.selectsPedido.formaPagamento = object.formaPagamento;
      this.selectsPedido.condicaoPagamento = object.condicaoPagamento;
      this.selectsPedido.informacoes = object.informacoes;
      this.selectsPedido.pedidoId = object.pedidoId;
      this.selectsPedido.oldClienteId = object.razao;
      this.selectsPedido.status = object.status;
    } else if (clienteAtual) {
      this.selectsPedido.razao = Number(clienteAtual);
    }

    this.buscaDadosCliente();
  },
  methods: {
    formataDocumento(documento) {
      return global.mascaraCpfCnpj(documento);
    },
    /*
     * Função: Method resposável por emitir o evento de fechar o modal de parametros.
     * Chamada por: Evento '@close' do componente 'Modal'.
     * */
    close() {
      this.$emit("close");
    },

    /**
     * Função: Metodo responsável por buscar razão social do modal
     * Chamada por método created
     */

    async buscaDadosCliente() {
      this.active_blackmodal = true;
      this.dadosPedido.unidade = "";
      this.dadosPedido.tabvnd = "";
      this.dadosPedido.formaPagamento = "";
      this.dadosPedido.condicaoPagamento = "";

      try {
        const response = await dpcAxios
          .connection()
          .get(process.env.VUE_APP_ECOMMERCE + api.env.BUSCA_CLIENTES);

        if (response.data.error == 0) {
          const { clientes } = response.data;

          const cliente = clientes.find(
            (x) => x.id == this.selectsPedido.razao
          );

          if (cliente) {
            this.buscaTabelaVendas();
          } else {
            this.selectsPedido.razao = "";
            this.selectsPedido.unidade = "";
            this.selectsPedido.tabvnd = "";
            this.selectsPedido.formaPagamento = "";
            this.selectsPedido.condicaoPagamento = "";
          }

          // Se o request só retornar 1 item, seleciona ele se não houver pedido já aberto para evitar multiplos requests
          if (clientes.length == 1 && this.selectsPedido.razao == "") {
            this.selectsPedido.razao = clientes[0].id;
            this.buscaTabelaVendas();
          }

          this.dadosPedido.razao = clientes.map((cli) => {
            cli.fullName = `${this.formataDocumento(cli.cpfcnpj)} - ${
              cli.nomerazao
            } - ${cli.cidade} - ${cli.uf}`;
            return cli;
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      this.active_blackmodal = false;
    },

    /**
     * Função: Metodo responsável por buscar tabela de vendas social do modal
     * Chamada watch do select anterior à ele
     */

    async buscaTabelaVendas() {
      this.active_blackmodal = true;

      this.dadosPedido.tabvnd = "";
      this.dadosPedido.formaPagamento = "";
      this.dadosPedido.condicaoPagamento = "";

      try {
        const response = await dpcAxios
          .connection()
          .get(
            `${
              process.env.VUE_APP_ECOMMERCE + api.env.BUSCA_TABELA_VENDA_INICIO
            }${this.selectsPedido.razao}/`
          );

        if (response.data.error == 0) {
          const { tabvnds } = response.data;

          // Se o request só retornar 1 item, seleciona ele se não houver pedido já aberto para evitar multiplos requests
          if (tabvnds.length == 1 && this.dadosPedido.tabvnd == "") {
            this.selectsPedido.tabvnd = tabvnds[0].id;
            // this.buscaFormaPagamento();
          }

          const tabvnd = tabvnds.find(
            (tabvnd) => tabvnd.id == this.selectsPedido.tabvnd
          );

          if (tabvnd) {
            this.buscaFormaPagamento();
          } else {
            this.selectsPedido.tabvnd = "";
            this.selectsPedido.formaPagamento = "";
            this.selectsPedido.condicaoPagamento = "";
          }

          this.dadosPedido.tabvnd = tabvnds;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      this.active_blackmodal = false;
    },

    /**
     * Função: Metodo responsável por buscar forma de pagamento do modal
     * Chamada watch do select anterior à ele
     */

    async buscaFormaPagamento() {
      if (typeof this.selectsPedido.tabvnd === "undefined") {
        return;
      }

      this.active_blackmodal = true;
      this.dadosPedido.formaPagamento = "";
      this.dadosPedido.condicaoPagamento = "";

      try {
        const response = await dpcAxios
          .connection()
          .get(
            `${
              process.env.VUE_APP_ECOMMERCE +
              api.env.BUSCA_FORMA_PAGAMENTO_INICIO
            }${this.selectsPedido.razao}/${api.env.BUSCA_FORMA_PAGAMENTO_FIM}${
              this.selectsPedido.tabvnd
            }`
          );

        if (response.data.error == 0) {
          const { formpgtos } = response.data;

          const formpgto = formpgtos.find(
            (formpgto) => formpgto.id === this.selectsPedido.formaPagamento
          );

          if (formpgto) {
            this.buscaCondicaoPagamento();
          } else {
            this.selectsPedido.formaPagamento = "";
            this.selectsPedido.condicaoPagamento = "";
          }

          // Se o request só retornar 1 item, seleciona ele se não houver pedido já aberto para evitar multiplos requests
          if (formpgtos.length == 1 && this.dadosPedido.formaPagamento == "") {
            this.selectsPedido.formaPagamento = formpgtos[0].id;
            this.buscaCondicaoPagamento();
          }

          this.dadosPedido.formaPagamento = formpgtos;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      this.active_blackmodal = false;
    },

    /**
     * Função: Metodo responsável por buscar condição de pagamento do modal
     * Chamada watch do select anterior à ele
     */

    async buscaCondicaoPagamento() {
      if (typeof this.selectsPedido.formaPagamento === "undefined") {
        return;
      }
      this.active_blackmodal = true;
      this.dadosPedido.condicaoPagamento = "";

      try {
        const response = await dpcAxios.connection().get(
          `${
            process.env.VUE_APP_ECOMMERCE +
            api.env.BUSCA_CONDICAO_PAGAMENTO_INICIO
          }${this.selectsPedido.razao}/${
            // Id selecionado na razão
            api.env.BUSCA_CONDICAO_PAGAMENTO_SEGUNDOMEIO
          }${this.selectsPedido.tabvnd}/${
            api.env.BUSCA_CONDICAO_PAGAMENTO_FIM
          }${this.selectsPedido.formaPagamento}`
        );

        if (response.data.error == 0) {
          const { condpgtos } = response.data;

          // Se o request só retornar 1 item, seleciona ele se não houver pedido já aberto para evitar multiplos requests
          if (
            condpgtos.length == 1 &&
            this.dadosPedido.condicaoPagamento == ""
          ) {
            this.selectsPedido.condicaoPagamento = condpgtos[0].id;
          }

          this.dadosPedido.condicaoPagamento = condpgtos;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      this.active_blackmodal = false;
    },

    /**
     * Função: Salva os dados do pedido no VUEX ao salvar o modal de parametros
     * Chamada por: Botão de salvar no '@click'
     */
    async saveDadosPedidos() {
      // Reseta erro
      this.error = 0;

      await this.validaCampos();

      // Se houve erro de validação dos campos
      if (this.error == 1) return false;

      await this.savePedido();

      // Se houve erro na validação do pedido
      if (this.error == 1) return false;

      bus.$emit("shouldUpdateCartItems", true);

      // todo: melhorar tratamento
      try {
        const selectedCondicao = this.dadosPedido.condicaoPagamento.find(
          (x) => x.id === this.selectsPedido.condicaoPagamento
        );

        this.$store.dispatch("paymentData", {
          card: {
            cardParcels: selectedCondicao.parcelas,
          },
        });
      } catch (e) {
        // ignora
      }

      this.$store.dispatch("setDadosPedido", this.selectsPedido);
      this.close();
    },

    /**
     * Função: Salva os dados do pedido no banco de dados
     * Chamada por: saveDadosPedidos() em caso de sucesso
     */

    async savePedido() {
      try {
        this.active_blackmodal = true;
        const verificaPedidoAberto = this.$store.getters.dadosPedido;

        if (Object.keys(verificaPedidoAberto).length === 0) {
          const response = await dpcAxios
            .connection()
            .post(process.env.VUE_APP_ECOMMERCE + api.env.NOVO_PEDIDO_EC, {
              cliente_id: this.selectsPedido.razao,

              tabvnd_id: this.selectsPedido.tabvnd,
              formpgto_id: this.selectsPedido.formaPagamento,
              condpgto_id: this.selectsPedido.condicaoPagamento,
            });
          if (response.data.error == 0) {
            this.active_blackmodal = false;
            const { pedido } = response.data;
            const { dadosPedido } = this;

            this.selectsPedido.pedidoId = pedido.id;

            await localStorage.setItem("pedidoaberto", pedido.id);

            // Mapeando as informações do pedido para salvar no store do vuex
            const { nomerazao } = dadosPedido.razao.find(
              (razao) => razao.id == this.selectsPedido.razao
            );

            const { descricao: tabvnd } = dadosPedido.tabvnd.find(
              (tabvnd) => tabvnd.id == this.selectsPedido.tabvnd
            );

            const { descricao: formaPagamento } =
              dadosPedido.formaPagamento.find(
                (formaPagamento) =>
                  formaPagamento.id == this.selectsPedido.formaPagamento
              );

            const { descricao: condicaoPagamento } =
              dadosPedido.condicaoPagamento.find(
                (condicaoPagamento) =>
                  condicaoPagamento.id == this.selectsPedido.condicaoPagamento
              );

            this.selectsPedido.informacoes = {
              nomerazao,
              tabvnd,
              formaPagamento,
              condicaoPagamento,
            };
          } else {
            this.active_blackmodal = false;
            this.error = 1;
            this.mensagem = response.data.message;
            return false;
          }
        } else {
          this.active_blackmodal = true;
          const response = await dpcAxios
            .connection()
            .put(
              process.env.VUE_APP_ECOMMERCE +
                api.env.ATUALIZA_PEDIDO_EC +
                this.selectsPedido.pedidoId,
              {
                cliente_id: this.selectsPedido.razao,
                tabvnd_id: this.selectsPedido.tabvnd,
                formpgto_id: this.selectsPedido.formaPagamento,
                condpgto_id: this.selectsPedido.condicaoPagamento,
                old_cliente_id: this.selectsPedido.oldClienteId,
              }
            );

          if (response.data.error == 0) {
            this.active_blackmodal = false;
            const { pedido } = response.data;
            const { dadosPedido } = this;

            // Mapeando as informações do pedido para salvar no store do vuex
            const { nomerazao } = dadosPedido.razao.find(
              (razao) => razao.id == this.selectsPedido.razao
            );

            const { descricao: tabvnd } = dadosPedido.tabvnd.find(
              (tabvnd) => tabvnd.id == this.selectsPedido.tabvnd
            );

            const { descricao: formaPagamento } =
              dadosPedido.formaPagamento.find(
                (formaPagamento) =>
                  formaPagamento.id == this.selectsPedido.formaPagamento
              );

            const { descricao: condicaoPagamento } =
              dadosPedido.condicaoPagamento.find(
                (condicaoPagamento) =>
                  condicaoPagamento.id == this.selectsPedido.condicaoPagamento
              );

            this.selectsPedido.informacoes = {
              nomerazao,
              tabvnd,
              formaPagamento,
              condicaoPagamento,
            };
          } else {
            this.active_blackmodal = false;
            this.error = 1;
            this.mensagem = response.data.message;
            return false;
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        this.active_blackmodal = false;
        bus.$emit("show-notification", [
          "error",
          "Desculpe.",
          "Ocorrou um erro ao iniciar seu pedido, por favor tente novamente mais tarde",
        ]);
      }
    },

    /**
     * Função: Valida campos do modal
     * Chamada por função saveDadosPedidos
     */

    validaCampos() {
      const razaoIsValid =
        this.dadosPedido.razao &&
        this.dadosPedido.razao.findIndex(
          (x) => x.id == this.selectsPedido.razao
        ) >= 0;

      this.mensagem = "";

      if (!razaoIsValid) {
        this.mensagem = "Por favor, selecione uma razão.";
      }

      if (!this.tabvndIsValid) {
        this.mensagem = "Por favor, selecione uma tabela de venda.";
      }

      if (!this.formaPagamentoIsValid) {
        this.mensagem = "Por favor, selecione uma forma de pagamento.";
      }

      if (!this.condicaoPagamentoIsValid) {
        this.mensagem = "Por favor, selecione uma condição de pagamento.";
      }

      if (this.mensagem != "") {
        this.error = 1;
      } else {
        this.error = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/_variables.scss";
.modal-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 12.5px;

  label {
    margin: 5px 0;
  }

  select {
    text-transform: uppercase;
    box-shadow: none;
    border-radius: 6px;

    &:focus {
      border-color: $brand-primary;
    }
  }
}
</style>

<style lang="scss">
@import "~@/assets/css/sass/bootstrap/_variables.scss";
#modal-container-select-razao {
  .IZ-select__item {
    color: grey;
  }

  .IZ-select__input input {
    font-size: 1.5rem !important;
    color: grey !important;
    padding: 3px 5px !important;
  }

  .IZ-select__input {
    height: 35px !important;

    font-size: 15px !important;
    border: 1px solid #ccc !important;
    border-radius: 6px !important;
  }

  .IZ-select__item.IZ-select__item--selected {
    color: $brand-primary !important;
    font-weight: 600;
  }
}

.black-modal {
  z-index: 1100 !important;
  display: flex;
  align-items: center;
  justify-content: center;

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
  }
}

.modal-parametros-compra {
  .modal-container.container {
    width: 40% !important;
  }
  // position: absolute;
  // z-index: 1050;

  .error-message {
    text-align: center;
  }
}
</style>
