<template>
  <div id="floating-cart">
    <button
      v-if="!isOnCart"
      tabindex="-1"
      class="btn-floating-cart"
      :style="{ zIndex: showFloatingCart ? '1' : null }"
      @keydown.27="close()"
      @click="showFloatingCart = !showFloatingCart"
    >
      <div class="cart-icon">
        <i class="fa fa-truck"></i>
        <span class="ci-total">R$ {{ mascaraValor(totalLiquido) }}</span>
      </div>
      <div class="cart-items-info">
        <div class="cii-qty">
          {{ cartItemsQty <= 999 ? cartItemsQty : "999+" }}
        </div>
        <!-- <div class="cii-text">{{ cartItemsQty == 1 ? "Item" : "Itens" }}</div> -->
      </div>
    </button>

    <template v-if="isAuthenticated && hasPedidoAberto">
      <div
        v-if="showItemUpdatedInfo && !showFloatingCart"
        class="item-updated-info"
        @click="showFloatingCart = !showFloatingCart"
      >
        <i class="fa fa-check text-color-primary"></i>
        <span>Seu caminhão foi atualizado. Toque para ver.</span>
      </div>
      <floatingBalloon
        v-if="showFloatingCart && !isOnCart"
        id="floating-cart-balloon"
        title="Caminhão"
        :subtitle="`(${cartItems.length})`"
        @close="close()"
      >
        <template slot="body">
          <div class="order-details od-shadow">
            <span v-if="dadosPedido.pedidoId" class="od-item">
              <span class="oi-left">Código</span>
              <small class="oi-right"> #{{ dadosPedido.pedidoId }}</small>
            </span>
            <span v-if="dadosPedido.informacoes.tabvnd" class="od-item">
              <span class="oi-left">Tabela</span>
              <small
                class="oi-right"
                :class="{
                  'text-danger': !dadosPedido.status.tabvnd,
                }"
                >{{ dadosPedido.informacoes.tabvnd }}
                <template v-if="!dadosPedido.status.tabvnd">
                  (indisponível)
                </template></small
              >
            </span>

            <span v-if="dadosPedido.informacoes.formaPagamento" class="od-item">
              <span class="oi-left">Pagamento</span>
              <small
                class="oi-right"
                :class="{
                  'text-danger':
                    !dadosPedido.status.formaPagamento ||
                    !dadosPedido.status.condicaoPagamento,
                }"
                >{{ dadosPedido.informacoes.formaPagamento }} -
                {{ dadosPedido.informacoes.condicaoPagamento }}
                <template
                  v-if="
                    !dadosPedido.status.formaPagamento ||
                    !dadosPedido.status.condicaoPagamento
                  "
                >
                  (indisponível)
                </template>
              </small>
            </span>

            <button
              class="btn btn-sm btn-link"
              style="
                width: 100%;
                justify-content: center;
                padding: 10px 0px 10px;
                font-weight: bold;
              "
              @click="toggleModalParams()"
            >
              <span v-if="dadosPedido.pedidoId" class="text-color-primary"
                >Alterar parâmetros do pedido</span
              >
              <span v-else class="text-color-primary">Novo pedido</span>
            </button>
          </div>

          <!-- Seller help -->
          <div class="separator-line"></div>
          <seller-help-card />
          <seller-send-cart-to-customer />
          <div class="separator-line"></div>
          <!-- Cart empty  -->
          <div v-if="cartItems.length < 1" class="cart-empty">
            <!-- <img src="@/assets/img/empty-cart.png" alt="Carrinho vazio" /> -->
            <span class="sad">:(</span>
            <h4>Seu caminhão está vazio!</h4>
            <h6>Que tal visualizar nosso catálogo? Clique no botão abaixo!</h6>
          </div>

          <!-- Cart full  -->
          <div v-else class="section-items">
            <h4>
              Itens <small>({{ cartItems.length }})</small>
            </h4>

            <div
              v-for="(item, index) in cartItems"
              v-if="!hideOrderItems || index < cartPreviewItemLimit"
              :key="item.integracao_id"
              class="item"
            >
              <router-link
                :to="{
                  name: 'produtoRota',
                  params: { codigo: item.integracao_id },
                }"
                class="item-img"
                :class="{ inactive: !item.status }"
                @click.native="close()"
              >
                <!-- <img
                  loading="lazy"
                  class="img-responsive"
                  :src="item.src"
                  :alt="item.descricao"
                  onerror="this.onerror=null;this.src='https://img.dpcnet.com.br/catalogo/imagem_indisponivel.jpg'"
                /> -->

                <ImageItem
                  custom-class="img-responsive"
                  :source="item.src"
                  :description="item.descricao"
                />
              </router-link>

              <div class="item-info">
                <router-link
                  v-if="item.descricao"
                  :to="{
                    name: 'produtoRota',
                    params: { codigo: item.integracao_id },
                  }"
                  @click.native="close()"
                >
                  {{ toTitleCase(item.descricao) }}
                </router-link>

                <div class="item-price">
                  <span class="price-each">
                    Qtd.: {{ item.qtd }} x R$
                    {{
                      mascaraValor(
                        item.valor -
                          item.vlrdesconto -
                          item.vlrstdesonerado +
                          item.vlrst
                      )
                    }}
                    <strike
                      v-if="item.vlrdesconto > 0 || item.vlrstdesonerado"
                      class="price-old"
                      >(R$ {{ mascaraValor(item.valor + item.vlrst) }})</strike
                    >
                  </span>
                  <h5 class="price-total">
                    R$
                    {{
                      mascaraValor(
                        roundValor(
                          item.valor -
                            item.vlrdesconto -
                            item.vlrstdesonerado +
                            item.vlrst,
                          2
                        ) * item.qtd
                      )
                    }}
                    <strike
                      v-if="item.vlrdesconto > 0 || item.vlrstdesonerado > 0"
                      class="price-old"
                    >
                      (R$
                      {{ mascaraValor((item.valor + item.vlrst) * item.qtd) }})
                    </strike>
                  </h5>
                </div>

                <template v-if="item.campanhasAtivas">
                  <small
                    v-for="campanha in item.campanhasAtivas"
                    v-if="campanha.detalhesCampanha && item.vlrdesconto > 0"
                    :key="campanha.id"
                    class="text-promotion"
                  >
                    <span v-if="campanha.display_discount">
                      Desconto {{ campanha.display_discount }} -
                      {{ campanha.detalhesCampanha.descricao }}
                    </span>
                    <span v-else-if="campanha.tpDiscount == 0">
                      Desconto R$
                      {{
                        mascaraValor(
                          campanha.detalhesCampanha.desconto * item.qtd
                        )
                      }}
                      - {{ campanha.detalhesCampanha.descricao }}
                    </span>
                    <span v-else>
                      Desconto {{ campanha.detalhesCampanha.desconto }}% -
                      {{ campanha.detalhesCampanha.descricao }}
                    </span>
                  </small>
                </template>
                <small
                  v-if="item.qtdestoque && item.qtdestoque <= item.qtd"
                  class="text-alert"
                >
                  Reajustamos a quantidade para
                  <b>{{ item.qtd }}</b
                  >, pois o número solicitado não está disponível.
                </small>

                <small v-if="!item.status" class="text-danger">
                  Esse produto está indisponível para seus parâmetros de compra,
                  para finalizar o pedido, remova-o do carrinho
                </small>
              </div>

              <item-qty-btn
                :simple="true"
                :removeitem="!item.status"
                :item="item"
                :can-remove-all="true"
                :is-cart-or-checkout="true"
              ></item-qty-btn>
            </div>

            <button
              v-if="cartItems.length > cartPreviewItemLimit"
              class="btn btn-sm btn-link"
              style="
                width: 100%;
                justify-content: center;
                padding: 10px 0px 0px 0px;
                font-weight: bold;
              "
              @click="hideOrderItems = !hideOrderItems"
            >
              <span v-if="hideOrderItems" class="text-color-primary"
                >Exibir todos os {{ cartItems.length }} itens
                <i
                  class="fa fa-chevron-down"
                  style="padding: 0 6px; font-size: 16px"
                ></i>
              </span>
              <span v-else class="text-color-primary"
                >Ocultar itens
                <i
                  class="fa fa-chevron-up"
                  style="padding: 0 6px; font-size: 16px"
                ></i>
              </span>
            </button>
          </div>
          <template v-if="cartItems.length > 0">
            <div class="separator-line"></div>
            <div class="order-details">
              <span class="od-item">
                <span class="oi-left"
                  >Subtotal ({{ cartItems.length }} itens)</span
                >
                <span class="oi-right">R$ {{ mascaraValor(totalBruto) }}</span>
              </span>
              <span class="od-item">
                <span class="oi-left">ICMS/ST </span>
                <span class="oi-right">R$ {{ mascaraValor(totalSt) }}</span>
              </span>
              <span v-if="totalStDesonerado > 0" class="od-item">
                <span class="oi-left">ICMS Desonerado </span>
                <span class="oi-right text-success"
                  >-R$ {{ mascaraValor(totalStDesonerado) }}</span
                >
              </span>
              <span class="od-item">
                <span class="oi-left">Desconto </span>
                <span class="oi-right text-success">
                  -R$ {{ mascaraValor(totalDesconto) }}
                </span>
              </span>
            </div>
          </template>
        </template>

        <template slot="subfooter">
          <div v-if="cartItems.length > 0" class="order-details od-shadow">
            <span class="od-item">
              <span class="oi-left">Total estimado</span>
              <h4 class="oi-right">R$ {{ mascaraValor(totalLiquido) }}</h4>
            </span>
          </div>
        </template>

        <template slot="footer">
          <small
            v-if="verificaStatus(cartItems)"
            class="floating-balloon-info cart-error"
          >
            <i class="mdi mdi-alert-circle-outline fa fa-1x"></i> Alguns
            produtos do pedido não estão mais disponívels. Por favor, remova-os
            do carrinho antes de prosseguir.
          </small>

          <button
            v-if="!isOrderParamsAvailable"
            class="btn btn-link floating-balloon-info cart-error"
            @click="toggleModalParams()"
          >
            <i class="mdi mdi-alert-circle-outline fa fa-1x"></i> Alguns
            parâmetros do pedido não estão mais disponíveis. Toque para
            corrigir.
          </button>

          <small
            v-if="
              regraPedidoMinimo &&
              isOrderParamsAvailable &&
              cartItems.length > 0
            "
            class="regra"
            v-html="regraPedidoMinimo.mensagem"
          />

          <!-- Cart empty  -->
          <template v-if="cartItems.length < 1">
            <div style="display: flex">
              <button
                class="btn btn-primary"
                style="flex: 1 0 auto"
                title="Alterar os parâmetros do pedido"
                @click="goToCatalogo()"
              >
                Visualizar Catálogo
              </button>
            </div>
          </template>

          <!-- Cart full  -->
          <template v-else>
            <div class="order-buttons">
              <button
                class="btn btn-secondary-outline btn-outline"
                title="Salvar pedido"
                @click="saveRequest()"
              >
                Salvar para depois
              </button>
              <button
                :disabled="
                  validaValorRegra || hasDisabledItem || !isOrderParamsAvailable
                "
                class="btn btn-primary"
                title="Enviar pedido"
                @click="goToCheckout()"
              >
                Concluir a compra
              </button>
            </div>
          </template>
        </template>
      </floatingBalloon>
    </template>
    <template v-else>
      <floatingBalloon
        v-if="showFloatingCart && !isOnCart"
        id="floating-cart-balloon"
        title="Caminhão"
        @close="close()"
      >
        <template slot="body">
          <div class="cart-empty">
            <!-- <img src="@/assets/img/empty-cart.png" alt="Carrinho vazio" />
             -->
            <span class="sad">:(</span>
            <template v-if="isAuthenticated && !hasPedidoAberto">
              <h5>Nenhum pedido em aberto.</h5>
              <button
                title="Fazer login"
                class="btn btn-sm btn-primary"
                @click="toggleModalParams()"
              >
                Novo Pedido
              </button>
            </template>
            <template v-else>
              <h5>
                Seu caminhão está vazio! Faça login ou cadastre-se para começar
                a comprar.
              </h5>
              <br /><br />
              <button
                title="Fazer login"
                class="btn btn-sm btn-primary"
                @click="redirectLogin()"
              >
                Faça login ou cadastre-se
              </button>
            </template>
          </div>
        </template>
        <template slot="footer">
          <div></div>
        </template>
      </floatingBalloon>
    </template>
    <ModalParams
      v-if="showModalParams"
      :show-modal="showModalParams"
      @close="showModalParams = false"
    ></ModalParams>

    <ModalSaveCart
      v-if="showModalSaveCart"
      @close="showModalSaveCart = false"
    ></ModalSaveCart>
  </div>
</template>

<script>
import cookie from "vue-cookie";
import ModalParams from "@/components/root/modal/ModalParametrosCompra.vue";
import floatingBalloon from "@/components/balloon/FloatingBalloon.vue";
import { mascaraValor, toTitleCase, roundValor } from "@/app/global.js";
import bus from "@/utils/events/bus";
import ItemQtyBtn from "@/components/produtos/ItemQtyBtn.vue";
import CartMixin from "@/app/checkout/cart/CartMixin";
import SellerHelpCard from "@/app/seller/components/SellerHelpCard.vue";
import SellerSendCartToCustomer from "@/app/seller/components/SellerSendCartToCustomer.vue";

import ModalSaveCart from "./ModalSaveCart";
import ImageItem from "@/components/image/ImageItem";

export default {
  name: "FloatingCart",
  components: {
    floatingBalloon,
    ModalParams,
    ItemQtyBtn,
    ModalSaveCart,
    ImageItem,
    SellerHelpCard,
    SellerSendCartToCustomer,
  },
  mixins: [CartMixin],
  data() {
    return {
      showFloatingCart: false,
      showModalParams: false,
      // isAuthenticated: false,
      showItemUpdatedInfo: false,
      mensagemRegra: "",
      valorRegra: 0,
      processingRequest: false,
      showModalSaveCart: false,
      hideOrderItems: true,
      cartPreviewItemLimit: 5,
      regraPedidoMinimo: null,
      // loading: false,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.getIsAuthenticated;
    },

    isOnCart() {
      if (!(this.$route && this.$route.matched[0])) return false;
      return !!["checkout"].find((x) => x === this.$route.matched[0].name);
    },
    hasDisabledItem() {
      return this.cartItems.find((item) => item.status === 0);
    },
    pedidoAtual() {
      return this.$store.getters.dadosPedido;
    },
    idPedidoAtual() {
      return this.$store.getters.dadosPedido.pedidoId;
    },
    cartItems() {
      return this.$store.getters.carrinhoAtual;
    },
    cartItemsQty() {
      return this.cartItems.length;
    },
    validaValorRegra() {
      if (!this.regraPedidoMinimo) return;

      if (
        this.regraPedidoMinimo.desconsiderast &&
        this.regraPedidoMinimo.valor >
          this.totalBruto - this.totalDesconto - this.totalStDesonerado &&
        this.totalBruto !== 0
      ) {
        return true;
      }
      if (!this.regraPedidoMinimo.desconsiderast) {
        if (
          this.regraPedidoMinimo.valor > this.totalLiquido &&
          this.totalLiquido !== 0
        ) {
          return true;
        }
      }

      return false;
    },
    // campanhaStatus() {
    //   console.log("oi");
    //   return this.$store.getters.campanhaStatus;
    // },
  },
  watch: {
    cartItemsQty(newValue, oldValue) {
      // if (newValue != oldValue) {
      //   // Disabled by dfault.
      //   this.showItemUpdatedInfo = true;
      //   setTimeout(() => {
      //     this.showItemUpdatedInfo = false;
      //   }, 3500);
      // }
    },
    showFloatingCart(newValue, oldValue) {
      if (this.isOnCart) {
        this.close();
      }

      if (newValue) {
        this.$store.dispatch("searchDiscountProducts");
      }

      // this is for posting events to react native.
      // if (window.ReactNativeWebView) {
      //   window.ReactNativeWebView.postMessage("pao_batata");
      // }
    },
    idPedidoAtual(newValue, oldValue) {
      this.regraPedidoMinimo = null;
      this.buscaRegraAtual();
    },
    pedidoAtual(newValue, oldValue) {
      this.regraPedidoMinimo = null;
      this.buscaRegraAtual();
    },
    // campanhaStatus(newValue, oldValue) {
    //   if (newValue) {
    //     this.buscaPedidoAberto();
    //   }
    // },
  },
  async mounted() {
    // Quando alterar dados do pedido, atualizar itens presentes no pedido atual
    bus.$on("shouldUpdateCartItems", () => {
      this.$nextTick(() => {
        this.buscaPedidoAberto();
      });
    });

    await this.buscaPedidoAberto();
  },
  async created() {
    bus.$on("openModalParametros", (e) => {
      this.showModalParams = e;

      this.toggleModalParams(e);
    });

    bus.$on("showFloatingCart", (e) => {
      this.showFloatingCart = e || false;
    });
  },
  methods: {
    roundValor,
    redirectLogin() {
      this.$router.push({
        name: "login",
      });
    },

    checkOrderDiscounts() {
      this.$store.dispatch("setCampanhaStatus");
    },
    toTitleCase,

    limpaCarrinho() {
      // Zerando quantidade para remover os itens do carrinho
      const carrinhoAtual = "shouldCleanCart";
      this.$store.dispatch("addItemCarrinhoAtual", carrinhoAtual);
      const dadosPedido = {};
      this.$store.dispatch("setDadosPedido", dadosPedido);
      localStorage.removeItem("pedidoaberto");
    },
    verificaStatus(status) {
      for (let i = 0; i < status.length; i += 1) {
        const error = status[i];
        if (error.status === 0) {
          return true;
        }
      }

      return false;
    },

    saveRequest() {
      this.close();
      this.showModalSaveCart = true;
    },
    mascaraValor,
    close() {
      this.showFloatingCart = false;
    },
    async buscaRegraAtual() {
      const idPedido =
        localStorage.getItem("pedidoaberto") || this.pedidoAtual.pedidoId;
      if (!idPedido) return;
      const response = await dpcAxios
        .connection()
        .get(
          `${
            process.env.VUE_APP_ECOMMERCE + api.env.NOVO_PEDIDO_EC
          }regra/${idPedido}`
        );
      const { regra, error } = response.data;
      if (error == 0) {
        if (regra) {
          this.regraPedidoMinimo = {
            valor: regra.vlrpedidomin,
            desconsiderast: regra.desconsiderast == 1,
            mensagem: regra.mensagem,
          };

          // this.valorRegra = regra.vlrpedidomin;
          // this.mensagemRegra = `O pedido mínimo de acordo com seus parâmetros de compra é R$ ${this.mascaraValor(
          //   regra.vlrpedidomin
          // )}`;
        }
      }
    },
    async buscaPedidoAberto() {
      const clienteId = localStorage.getItem("clienteAtual");

      if (!Number(clienteId)) {
        return;
      }

      const loadingId = Date.now() + Math.random();
      this.$store.dispatch("setShowLoadingModal", [true, loadingId]);

      const cartId =
        localStorage.getItem("pedidoaberto") ||
        this.pedidoAtual.pedidoId ||
        null;

      try {
        await this.restoreCart(cartId, clienteId);
      } catch (e) {
        console.log(e);
        localStorage.removeItem("pedidoaberto");
        this.$store.dispatch("setDadosPedido", []);
      }

      this.$store.dispatch("setShowLoadingModal", [false, loadingId]);
    },

    goToCheckout() {
      this.close();
      this.$router.push({
        name: "carrinhoRota",
        params: {},
      });
    },
    goToCatalogo() {
      this.close();
      this.$router.push({
        name: "catalogoRota",
        query: {
          // busca: this.$route.query.busca,
          // categoria: null,
          fornecedor: [],
          sub: [],
          ordem: this.$route.query.ordem,
        },
      });
    },
    toggleModalParams(e) {
      this.close();
      if (this.isAuthenticated) {
        this.showModalParams = e || true;
      }
    },
    limiteMensalExedido(campanhaId) {
      return (
        this.$store.getters.campanhaStatus[campanhaId].vlrRestanteCredito <= 0
      );
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/css/sass/bootstrap/variables";

.cart-error {
  color: #d9534f !important;
  border-radius: 7px;
  // text-transform: uppercase;
  margin: 0 5px 10px;
}

#floating-cart-balloon {
  position: fixed;
  height: 100%;
  top: 0;
  border-radius: 0;
  .text-promotion {
    color: #007538;
  }

  position: relative;
  top: 10px;
  .sad {
    font-size: 100px;
    margin-bottom: 30px;
  }
  .text-danger {
    color: $brand-danger !important;
  }
  .text-alert {
    color: $brand-warning !important;
  }
  .cart-empty {
    text-align: center;
    padding: 20px;
    h4 {
      color: $gray-dark;
    }
    img {
      opacity: 0.6;
      width: 150px;
    }
  }
  .item {
    display: flex;
    padding: 12px 0px;
    border-bottom: 1px solid $gray-lighter;

    &:last-child {
      border-bottom: none;
    }

    .item-img {
      height: 65px;
      width: 65px;
      min-width: 60px;
      text-align: center;
      // margin-bottom: 5px;
      display: grid;
      -webkit-box-align: center;
      -ms-flex-align: center;
      // border-left: 2px solid #1d984e;
      align-items: center;
      overflow: hidden;

      &.inactive {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
      }

      img {
        margin: 0 auto;
        max-height: 60px;
        padding: 2px;
        max-width: 60px;
      }
    }

    .item-info {
      flex: 1 1 auto;
      padding: 0 10px;
      color: #666;
      display: flex;
      flex-direction: column;
      align-self: center;
      .item-cod {
        color: $gray-light;
        font-size: 13px;
        // margin: 5px 0;
        font-weight: 600;
      }
      a {
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 15px;
        color: #545454;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        font-weight: 400;
        font-family: "Roboto", sans-serif;

        &:hover {
          color: var(--brand-primary, $brand-primary);
        }
      }
    }
    .item-price {
      white-space: nowrap;
      flex: 0 1 auto;
      text-align: left;
      color: $gray;
      margin: 5px 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      // -ms-flex-item-align: center;
      // align-self: center;
      .price-old {
        font-size: 11px;
        font-weight: normal;
        color: #a2a2a2;
      }
      .price-total {
        margin: 1px 0;
        font-weight: 700;
        color: $gray-dark;
      }
      .price-each {
        color: $gray;
        font-size: 12px;
      }
    }
    .item-qty {
      white-space: nowrap;
      flex: 0 1 auto;
      text-align: left;
      color: #666;
      padding-top: 2px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      // -ms-flex-direction: column;
      // flex-direction: column;
      // -ms-flex-item-align: center;
      align-self: center;
      .button-plus {
        border-radius: 0px 5px 5px 0px;
      }
      .button-minus {
        border-radius: 5px 0px 0px 5px;
      }
      /* For Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      /* Webkit browsers like Safari and Chrome */
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .qty {
        max-width: 25px;
        outline: 0 !important;
        border: 1px solid transparent;
        padding: 0;
        height: 25px;
        text-align: center;
        font-size: 14px;
        &:focus {
          border-color: var(--brand-primary, $brand-primary);
          border-width: 1px;
        }
      }
      button {
        border: 0;
        margin: 0;
        font-size: 13px;
        padding: 0;
        width: 30px;
        height: 25px;
        background: transparent;
        i {
          color: $gray-light;
        }
      }
    }
    //chang it later
  }
  .floating-balloon-footer {
    flex-direction: column;
    max-height: 140px;

    .regra {
      text-align: center;
      margin: 0 5px 10px;
      color: #14773d;
    }
    button {
      margin: 2.5px 2.5px;
    }
  }

  .section-items {
    padding: 10px 15px;
    // color: #333;
    color: $gray-dark;
  }

  .separator-line {
    height: 8px;
    background: $gray-lighter;
  }

  .order-details {
    // background: white;
    padding: 5px 20px 0px 20px;
    font-size: 12px;
    display: flex;
    color: $gray-dark;
    flex-direction: column;

    &.od-shadow {
      box-shadow: 0 0px 5px 0px #00000030;
    }

    .od-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .od-item {
      font-weight: 700;
      flex: 1;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-family: "Roboto";
      border-bottom: 1px solid rgb(241, 241, 242);
      padding: 7.5px 0px;
      place-items: center;

      &:first-child {
        font-size: 16px;
      }

      &:last-child {
        border-bottom: none;
      }

      .oi-right {
        margin: 0;
        padding: 0;
      }
    }

    .details-left {
      font-weight: 700;
      color: #333;
      margin-bottom: 5px;
      font-size: 12px;
      display: grid;
      text-align: left;
      flex: 1 1 auto;
      // small {
      //   font-size: 12px;
      //   font-weight: 400;
      // }
    }
    .details-right {
      text-align: right;
      flex: 1 1 auto;
      .total-cart-price {
        font-size: 20px;
        font-weight: 600;
        // color: rgb(51, 51, 51);
        // color: #14773d;
        small {
          color: $gray;
        }
      }
    }
    small {
      // color: $green-darker;
      font-size: 12px;
      font-weight: 600;
    }
  }
  .order-buttons {
    // display: inline-table;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    button {
      flex: 1 0 auto;
    }
  }
}
#floating-cart {
  .btn-floating-cart {
    white-space: nowrap;
    display: flex;
    padding: 0px 15px;
    background: transparent;
    height: 40px;
    overflow: hidden;
    border-radius: 5px;
    // color: #ffffff;
    font-weight: bold;
    border: none;
  }
  .item-updated-info {
    position: absolute;
    background: white;
    padding: 5px 12px;
    white-space: nowrap;
    border-radius: 4px;
    right: 0;
    border: 1px solid gainsboro;
    box-shadow: 0px 0px 10px -3px #666;
    font-size: 14px;
    cursor: pointer;
    margin-top: 5px;

    &:before {
      content: "";
      position: absolute;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      top: -12px;
      border-bottom: 12px solid white;
      right: 20%;
    }
  }
  .cart-items-info {
    height: 22px;
    background: var(--brand-accent, $brand-accent);
    border-radius: 30px;
    font-size: 13px;
    color: var(--brand-primary, $brand-primary);
    width: auto;
    padding: 2px;
    min-width: 22px;
    position: absolute;
    line-height: 12.5px;
    right: -4px;
    top: -5px;
    border: 3px solid var(--brand-primary, $brand-primary);
  }
  .cart-icon {
    padding: 4px 0px;
    display: flex;
    align-self: flex-end;
    margin-right: -7px;
    flex-direction: column;
    align-items: flex-end;

    .ci-total {
      font-size: 10px;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
    }
    i {
      padding: 0 5px;
      font-size: 23px;
    }
  }
}
// @media (max-width: 375px) {
//   .cart-items-info {
//     display: none;
//   }
// }
//Responsive
@media (max-width: 556px) {
  #floating-cart-balloon {
    .floating-balloon-body {
      height: calc(100% - 152px - 69px);
      max-height: unset;
    }
    .floating-balloon-footer {
      button {
        flex: 1;
        // margin: 0 2.5px;
      }
    }
  }
  //Provisório para apresentaçã;
  #floating-cart {
    position: relative;
    margin: 0;

    .btn-floating-cart {
      background: transparent;
      // height: auto;
      margin: 0;
      min-width: 0px !important;
      padding: 0 15px 0px 0px;

      .cart-items-info {
        position: absolute;
        background: var(--brand-accent, $brand-accent);
        // min-width: 25px;
        // height: 25px;
        padding: 0;
        margin: 0;
        bottom: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        border-radius: 50%;
        right: 5px;
        font-size: 12px;
        color: var(--brand-primary, $brand-primary);
        font-weight: bold;
        justify-content: center;
        // border: 2px solid var(--brand-accent, $brand-accent);

        .cii-text {
          display: none;
        }
      }
      .cart-icon {
        padding: 0;
        i {
          font-size: 23px;
          position: relative;
          right: 7px;
          padding: 0 5px;
        }
      }
    }
  }
}
//Responsive mobile small
@media (max-width: 375px) {
  .btn-floating-cart {
    left: -45px;
    // padding: 0px 2px !important;
    min-width: 0px !important;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
