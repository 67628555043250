// import Atendimento from "./components/Atendimento";
// import Faq from "./components/faq/FaqContent";

export default [
  {
    path: "/atendimento",
    name: "Atendimento",

    component: () =>
      import(
        /* webpackChunkName: "view-atendimento" */ `./components/Atendimento.vue`
      ).catch((error) => {
        location.reload();
      }),
    redirect: "/atendimento/topicos/1",

    beforeEnter(to, from, next) {
      let contatoUrl = this.$store.getters.siteConfig.app_store_contato_url;

      if (contatoUrl) {
        location.href = contatoUrl;
        return;
      }
      next();
    },
    children: [
      {
        path: "topicos/:topicoId?",
        name: "AtendimentoFaqContent",
        component: () =>
          import(
            /* webpackChunkName: "view-atendimento-faq" */ `./components/faq/FaqContent.vue`
          ).catch((error) => {
            location.reload();
          }),
      },
    ],
  },
];
