export const informacaoGlobal = ({ informacao_global }) => informacao_global;

export const getToken = ({ token }) => token;

export const user = ({ user }) => user;

export const dadosPedido = ({ dadosPedido }) => dadosPedido;

export const carrinhoAtual = ({ carrinhoAtual }) =>
  carrinhoAtual.sort((a, b) => {
    if (a.status === 0) return -1;
    if (b.status === 0) return 1;
    return 0;
  });

export const paymentData = ({ paymentData }) => paymentData;

export const siteConfig = ({ siteConfig }) => siteConfig;

export const totalProduct = ({ totalProduct }) => totalProduct;

export const faturamentoData = ({ faturamentoData }) => faturamentoData;

export const saveCards = ({ saveCards }) => saveCards;

export const savedCardSelected = ({ savedCardSelected }) => savedCardSelected;

export const cvvCardSave = ({ cvvCardSave }) => cvvCardSave;

export const campanhasDisponiveis = ({ campanhasDisponiveis }) =>
  campanhasDisponiveis;

export const getHasMoreCampaignsToShow = ({ hasMoreCampaignsToShow }) =>
  hasMoreCampaignsToShow;

export const campanhaStatus = ({ campanhaStatus }) => campanhaStatus;

export const globalAppLoading = ({ globalAppLoading }) => globalAppLoading;
export const cardPaymentError = ({ cardPaymentError }) => cardPaymentError;

// export getUsuarioInfo from state
export const getUsuarioInfo = ({ usuarioInfo }) => usuarioInfo;

export const productsWithCampanha = ({ productsWithCampanha }) =>
  productsWithCampanha;

export const clienteAtual = ({ clienteAtual }) => clienteAtual;

export const getIsAuthenticated = ({ isAuthenticated }) => isAuthenticated;

export const getUserShouldResetPassword = ({ userShouldResetPassword }) =>
  userShouldResetPassword;

export const isDpcMobileWebView = ({ isDpcMobileWebView }) =>
  isDpcMobileWebView;
